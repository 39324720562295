<template>
  <v-footer
    id="home-footer"
    color="black"
    dark
    min-height="72"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          class="text-md-right text-sm-center text-center"
          cols="12"
          md="5"
        >
          {{ authTime }}
          <a
            class="white--text ml-6"
            @click="onItemClick"
          >
            {{ beianId }}
          </a>
        </v-col>
        <v-col
          class="hidden-sm-and-down"
          cols="12"
          md="5"
        >
          <span class="white--text ml-2"> {{ copyright }} </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',
    data () {
      return {
        authTime: this.$init.authTime,
        beianId: this.$init.beianId,
        copyright: this.$init.copyright,
        social: [
          'Facebook',
          'Twitter',
          'Instagram',
          'Linkedin',
        ],
      }
    },
    methods: {
      onItemClick (ev) {
        // window.location.href = "http://beian.miit.gov.cn"
        window.open('http://beian.miit.gov.cn', '_blank')
      }
    }
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
